<template>
  <ion-page>
    <account-header />
    <skeleton-account v-if="isLoading" />
    <ion-content id="content" v-else>
      <account-info :key="reRenderUserInfo" :userData="userData" />
      <card-invoice-account />
      <card-list ref="cardListRef" v-if="isShowCard" :currentActiveCompany="currentActiveCompany" />
      <div class="spacer"></div>
      <extra-info />
      <div class="spacer"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ACTIONS } from '@/modules/b2b/store/account/actions';
import SkeletonAccount from '@/modules/shared/components/accounts/SkeletonAccount.vue';
import { onIonViewDidEnter } from '@ionic/vue';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import AccountHeader from './components/AccountHeader.vue';
import AccountInfo from './components/AccountInfo.vue';
import CardInvoiceAccount from './components/CardInvoiceAccount.vue';
import CardList from './components/CardList.vue';
import ExtraInfo from './components/ExtraInfo.vue';
export default defineComponent({
  name: 'Account',
  components: {
    AccountHeader,
    AccountInfo,
    CardList,
    ExtraInfo,
    CardInvoiceAccount,
    SkeletonAccount
  },
  setup() {
    const store = useStore();
    const storage = inject('$storage');
    const cardListRef = ref(null);
    const isLoading = ref(false);
    const userData = ref(null);
    const isShowCard = ref(true);
    const reRenderUserInfo = ref(1);
    const currentActiveUser = computed(() => store.getters[`b2b/account/user`]);
    const currentActiveCompany = ref(null);
    const setLoading = (state) => (isLoading.value = state);
    onMounted(async () => {
      setLoading(true);
      const { country } = await storage.getUser();
      await store.dispatch(`b2b/account/${ACTIONS.GET_CURRENT_USER}`);
      userData.value = currentActiveUser.value;
      if (country.id === 96) isShowCard.value = false;
      setLoading(false);
    });
    watch(currentActiveUser, (updatedUserData) => {
      userData.value = updatedUserData;
      reRenderUserInfo.value++;
    });
    onIonViewDidEnter(async () => {
      currentActiveCompany.value = await storage.getSelectedCompany();
      if (cardListRef.value) cardListRef.value.handleGetData();
    });
    return {
      cardListRef,
      isShowCard,
      currentActiveCompany,
      userData,
      isLoading,
      reRenderUserInfo
    };
  }
});
</script>
