<template>
  <ion-header class="custom-header">
    <ion-toolbar class="custom-toolbar">
      <ion-title class="fs-3 text-center fw-bold">{{ $t('tell_a_friend') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content scroll-y="false">
    <ion-grid>
      <ion-row>
        <ion-col
          v-for="item in socialInfos"
          :key="item.name"
          size="3"
          class="d-flex flex-column align-center py-3"
        >
          <a @click="shareApp(item.shareLink)" rel="noopener noreferrer">
            <ion-img :src="item.logoUrl" class="social-img" :alt="item.altImg" />
          </a>
          <ion-text>
            <div class="mt-2 fw-bold">{{ item.name }}</div>
          </ion-text>
        </ion-col>

        <ion-col size="3" class="d-flex flex-column align-center py-3">
          <div @click="copyMessage">
            <ion-icon color="grey" class="copy-icon" slot="icon-only" :icon="copyOutline" />
          </div>
          <ion-text>
            <div class="mt-2 fw-bold">{{ $t('copy') }}</div>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { Clipboard } from '@capacitor/clipboard';

export default defineComponent({
  name: 'SocialMediaShare',
  data() {
    return {
      message: this.$t('social_shared_mess') + this.download_link,
      socialInfos: [
        {
          name: this.$t('wa'),
          shareLink:
            'whatsapp://send?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%20' +
            this.download_link,
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
          altImg: this.$t('wa_alt_img')
        },
        {
          name: this.$t('telegr'),
          shareLink:
            'https://t.me/share/url?url=' +
            this.download_link +
            '&text=%0AHi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%20Download%20now%20by%20clicking%20on%20the%20link%20above',
          logoUrl: 'https://cdn3.iconfinder.com/data/icons/social-icons-33/512/Telegram-256.png',
          altImg: this.$t('telegr_alt_img')
        },
        {
          name: this.$t('facebook'),
          shareLink:
            'https://m.facebook.com/sharer/sharer.php?display=popup&u=' +
            encodeURIComponent(this.download_link),
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/facebook-256.png',
          altImg: this.$t('facebook_alt_img')
        },
        {
          name: this.$t('twitter'),
          shareLink:
            'https://twitter.com/intent/tweet/?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%20' +
            this.download_link +
            '&amp;url=',
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/twitter-256.png',
          altImg: this.$t('twitter_alt_img')
        },
        {
          name: this.$t('email'),
          shareLink:
            'mailto:?subject=TreeDots%20App%20Download%20Link&amp&body=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%20' +
            this.download_link,
          logoUrl: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
          altImg: this.$t('email_alt_img')
        }
      ]
    };
  },
  setup() {
    let mode = process.env.VUE_APP_ENV;
    let download_link = process.env.VUE_APP_DOWNLOAD_LINK;
    return {
      copyOutline,
      mode,
      download_link
    };
  },
  emits: ['close-modal'],
  methods: {
    shareApp(shareUrl) {
      window.open(shareUrl, '_blank');
    },
    async copyMessage() {
      await Clipboard.write({ string: this.message });
      this.$emit('close-modal');
    }
  }
});
</script>

<style scoped>
.custom-header.header-md::after {
  background-image: none;
}
.custom-toolbar {
  padding: 8px 16px;
  --border-width: 0px !important;
}
.social-img {
  width: 56px;
  height: 56px;
}
.copy-icon {
  font-size: 56px;
}
</style>
