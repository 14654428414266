<template>
  <div class="px-4" v-if="userData">
    <div class="avatar">
      <ion-avatar class="avatar-image">
        <td-image :image="userData.image" :imagePath="IMAGE_PATH.USER"></td-image>
        <div class="avatar-border">&nbsp;</div>
      </ion-avatar>
    </div>
    <div class="d-flex justify-center align-center flex-column avatar-info">
      <div class="full-name">
        {{ `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}` }}
      </div>
      <div class="first-name text-capitalize">{{ userData?.type?.name ?? '-' }}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex mt-3">
        <div class="col-auto fs-3 text-grey text-light">{{ $t('phone') }}</div>
        <div class="col ml-auto text-right text-black ellipsis fs-3">
          {{ userData?.mobile ?? '-' }}
        </div>
      </div>

      <div class="d-flex mt-3">
        <div class="col-auto fs-3 text-grey text-light">{{ $t('email') }}</div>
        <div class="col ml-auto text-right text-black ellipsis fs-3">
          {{ userData?.email ?? '-' }}
        </div>
      </div>

      <div class="d-flex mt-3">
        <div class="col-auto fs-3 text-grey text-light">{{ $t('gender') }}</div>
        <div class="col ml-auto text-right text-black ellipsis fs-3">
          {{ getGenderName(userData?.gender) }}
        </div>
      </div>
    </div>
    <div>
      <ion-button
        class="text-white w-100 mt-4 text-initial btn-account"
        router-link="/b2b/update-profile"
        color="primary"
      >
        {{ $t('update_profile') }}
      </ion-button>
      <div v-if="userData?.user_type_id !== SUB_BUYER_ID">
        <ion-button
          class="text-white w-100 text-initial btn-account"
          router-link="/b2b/customers"
          color="primary"
        >
          {{ $t('accountPage.manage_companines') }}
        </ion-button>
        <ion-button
          class="text-white w-100 text-initial btn-account ion-hide"
          router-link="/b2b/connect-peppol"
          color="primary"
        >
          {{ $t('accountPage.configure_peppol_identifier') }}
        </ion-button>
        <ion-button
          class="text-white w-100 text-initial btn-account ion-hide"
          router-link="/b2b/request-product"
          color="primary"
        >
          {{ $t('accountPage.request_product') }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { SUB_BUYER_ID } from '@/modules/b2b/constants';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { getGenderName } from '@/modules/b2b/services/libs/helper';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AccountInfo',
  props: {
    userData: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    return {
      SUB_BUYER_ID,
      getGenderName,
      IMAGE_PATH
    };
  }
});
</script>

<style scoped lang="scss">
.text-light {
  color: #9e9e9e;
}
.banner {
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 14px;
}
.banner p {
  padding: 8px;
  margin-bottom: 0;
  margin-top: 0;
}
.avatar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
}

.avatar-image {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  position: relative;
}

.avatar-border {
  border-radius: 50%;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  border: 3px solid rgb(100, 174, 15);
}

.avatar-info .full-name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
}

.avatar-info .first-name {
  color: #9e9e9e;
  font-size: 12px;
}

.btn-account {
  height: 2.4em;
}

.btn-account::part(native) {
  font-size: 14px;
  border-radius: 10px;
}
</style>
