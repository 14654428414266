<template>
  <div>
    <ion-item
      lines="none"
      class="ion-item-card mt-2 mb-2"
      v-for="(netCard, index) in listNetsCard"
      :key="index"
    >
      <div class="d-flex align-center w-100 card-item" v-if="isAccoutPage">
        <div>
          <ion-img class="card-image" :src="NetsCardImage" />
        </div>
        <div class="ml-3">
          <div class="card-name fs-3">
            {{ netCard.issuer_short_name }} {{ $t('accountPage.nets_card_type') }}
          </div>
          <div class="card-info">
            *{{ netCard.last_4_digits_fpan }} {{ netCard.mtoken_expiry_date.substr(2) }}/{{
              netCard.mtoken_expiry_date.substr(0, 2)
            }}
          </div>
        </div>
        <div class="ml-auto d-flex align-center">
          <div class="mr-1 card-badge">{{ $t('accountPage.default') }}</div>
          <div class="px-1 d-flex align-center" @click="handleRemoveNetsCard">
            <ion-icon color="danger" slot="icon-only" :icon="trashOutline" class="card-icon" />
          </div>
        </div>
      </div>
    </ion-item>
    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import NetsCardImage from '@/assets/images/nets-brand.png';
import { capitalizeFirstLetter } from '@/modules/b2b/services/libs/helper';
import { stripe } from '@/modules/b2b/services/libs/payment';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { Device } from '@capacitor/device';
import { trashOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');
// import Payment from '@/plugins/nets-payment-plugin.js';
import { presentAlert } from '@/modules/shared/utils/';

export default defineComponent({
  setup() {
    return {
      trashOutline,
      capitalizeFirstLetter,
      NetsCardImage,
      presentAlert
    };
  },
  props: ['isAccoutPage'],
  computed: {
    ...mapGetters(['listNetsCard', 'status', 'error'])
  },
  inject: ['$storage'],
  data() {
    return {
      selectCompany: [],
      deviceId: '',
      isOpenLoadingref: false
    };
  },
  async created() {
    this.selectCompany = await this.$storage.getSelectedCompany();

    // get device id
    this.deviceId = (await Device.getId()).uuid;
  },
  methods: {
    ...mapActions([ACTIONS.REMOVE_NETS_CARD, ACTIONS.GET_LIST_NETS_CARD]),
    setOpenLoading(state) {
      this.isOpenLoadingref = state;
    },
    async handleRemoveNetsCard() {
      await this.presentAlert({
        mode: 'ios',
        header: 'Delete Card?',
        message:
          'Once you delete the existing NETS Card from this device, gonna be deleted to all companies.',
        buttons: [
          {
            text: 'Cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.removeCardNets();
            }
          }
        ]
      });
    },
    async removeCardNets() {
      // await this.setOpenLoading(true);
      // await this[ACTIONS.REMOVE_NETS_CARD]({
      //   deviceId: this.deviceId
      // });
      // let payload;
      // if (this.status) {
      //   // remove all net card
      //   await Payment.onDeregistration();
      //   // show add NET card again
      //   this.$emit('showAddNetsBankCard');
      //   await this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId);
      //   payload = {
      //     color: TOAST_COLOR.BLACK,
      //     message: this.$t('card_has_been_deleted')
      //   };
      // } else {
      //   payload = {
      //     color: TOAST_COLOR.DANGER,
      //     message: this.error.message
      //   };
      // }
      // await this.setOpenLoading(false);
      // await openToast(payload);
      // this.$emit('showAddNetsBankCard');
    },

    cardImg(brand) {
      return stripe.getCardImage(brand);
    }
  }
});
</script>
<style scoped>
.card-item {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-success-25);
  padding: 15px 16px;
  border-radius: 4px;
}

.card-name {
  font-weight: 500;
  color: #313131;
  margin-bottom: 5px;
}

.card-info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.card-image {
  width: 40px;
  height: auto;
}

.card-icon {
  color: #757575;
}

.card-badge {
  color: white;
  background: #04565a;
  padding: 4px;
  font-size: 10px;
  border-radius: 3px;
  opacity: 0.9;
}
ion-icon {
  font-size: 20px;
}
</style>
<style>
.ion-item-card::part(native) {
  padding: 0px;
  --inner-padding-end: 0px;
}
.card-popover .popover-content {
  --min-width: 120px;
  --width: 150px;
}
ion-popover {
  --min-width: 120px;
  --width: 150px;
}
</style>
