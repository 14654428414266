<template>
  <ion-content>
    <ion-label>
      <ion-skeleton-text
        :animated="true"
        style="width: 30%; height: 15%; border-radius: 50%; margin-top: 1.5rem; margin: 0 auto"
      ></ion-skeleton-text>
    </ion-label>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 50%; height: 20px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 30px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 30px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 30px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 100px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <div class="mx-2 d-flex">
      <ion-skeleton-text
        :animated="true"
        style="width: 49%; height: 100px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
      <ion-skeleton-text
        :animated="true"
        style="width: 49%; height: 100px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 75px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 40px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
  </ion-content>
</template>
<script>
export default {
  name: 'SkeletonPaymentDetails'
};
</script>
