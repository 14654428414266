<template>
  <ion-item v-if="card" part="item" lines="none" class="ion-item-card">
    <div class="d-flex align-center w-100 card-item">
      <div>
        <ion-img class="card-image" :src="cardImg(card.brand)" />
      </div>
      <div class="ml-3">
        <div class="card-name fs-3">{{ capitalizeFirstLetter(card.brand) }} ({{ card.funding }})</div>
        <div class="card-info">*{{ card.last4 }} {{ card.exp_month }}/{{ card.exp_year }}</div>
      </div>
      <div class="ml-auto d-flex align-center">
        <div v-if="isDefault && !isNetCard" class="mr-1 card-badge">{{ $t('accountPage.default') }}</div>
        <div class="px-1 d-flex align-center" @click="openModalEdit">
          <ion-icon slot="icon-only" :icon="ellipsisVertical" class="card-icon" />
        </div>
      </div>
    </div>
  </ion-item>
</template>

<script>
import { capitalizeFirstLetter } from '@/modules/b2b/services/libs/helper';
import { ellipsisVertical } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { stripe } from '@/modules/b2b/services/libs/payment';

export default defineComponent({
  props: {
    card: {
      required: true
    },
    isDefault: {
      default: false
    },
    isNetCard: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const isOpenRef = ref(false);
    const event = ref();
    const setOpen = (state, event) => {
      event.value = event;
      isOpenRef.value = state;
    };
    return {
      ellipsisVertical,
      isOpenRef,
      setOpen,
      event,

      capitalizeFirstLetter
    };
  },
  methods: {
    cardImg(brand) {
      return stripe.getCardImage(brand);
    },
    openModalEdit() {
      this.$emit('open-modal-edit', this.card, this.isDefault);
    }
  }
});
</script>
<style scoped>
.card-item {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-success-25);
  padding: 15px 16px;
  border-radius: 4px;
}

.card-name {
  font-weight: 500;
  color: #313131;
  margin-bottom: 5px;
}

.card-info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.card-image {
  width: 40px;
  height: auto;
}

.card-icon {
  color: #757575;
}

.card-badge {
  color: white;
  background: #04565a;
  padding: 4px;
  font-size: 10px;
  border-radius: 3px;
  opacity: 0.9;
}
ion-item:not(:first-child) {
  margin-top: 10px;
}
</style>
<style>
.ion-item-card::part(native) {
  padding: 0px;
  --inner-padding-end: 0px;
}
.card-popover .popover-content {
  --min-width: 120px;
  --width: 150px;
}
</style>
