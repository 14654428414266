<template>
  <div class="form-language">
    <div class="title">{{ $t('select_preferred_language') }}</div>
    <div class="language-select">
      <ion-list>
        <ion-radio-group :value="language" mode="ios">
          <ion-item
            v-for="(data, index) in defaultLanguages"
            @click="handleUpdateLanguage(data.key)"
            :key="index"
          >
            <ion-label>{{ data.value }}</ion-label>
            <ion-radio slot="end" :value="data.key"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </div>
</template>

<script>
import { apolloClient } from '@/main';
import localeChinese from '@/plugins/both/dayjs';
import { updateLanguage } from '@/services/shared/graphql';
import CleverTap from '@/services/shared/helper/clevertap.js';
import { defaultLanguages } from '@/services/shared/helper/countries';
import dayjs from 'dayjs';
import { defineComponent, inject, onMounted, ref } from 'vue';

import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'LanguagePreferences',
  emits: ['closeModal'],
  setup(_, { emit }) {
    const { locale } = useI18n();
    const storage = inject('$storage');
    const language = ref(``);
    const handleUpdateLanguage = async (lang) => {
      locale.value = lang;
      const { data } = await apolloClient.mutate({
        mutation: updateLanguage,
        variables: {
          language: lang
        }
      });
      await storage.set('language', data.updateLanguage.language);
      const [getLocale] = lang.split('-');
      dayjs.locale(getLocale === `zh` ? localeChinese : getLocale);
      emit('closeModal');
      await CleverTap.updateUserLanguage(lang);
    };
    onMounted(async () => {
      language.value = await storage.get('language');
    });

    return {
      locale,
      language,
      defaultLanguages,
      handleUpdateLanguage
    };
  }
});
</script>

<style src="./styles.scss" lang="scss" scoped></style>
